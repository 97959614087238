import { Icon, Input } from "antd";
import Form, { FormComponentProps, FormCreateOption } from "antd/lib/form";
import { forgotPassword } from "client/services/api";
import * as apiIndependentFunctions from "client/services/apiFunctions";
import { openNotificationWithIcon } from "client/utils";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { getUserErrorMessage } from "../../../../services/api";
import { AuthPageSubTitle, LoginFormButton, PageHeader, PageTitleWrapper } from "../../styles";
import { AuthPageTemplate } from "../Common/AuthPageTemplate";

const FormItem = Form.Item;

type Props = FormComponentProps;

const pageTitle = (
  <PageTitleWrapper>
    <PageHeader>Retrieve Access</PageHeader>
  </PageTitleWrapper>
);
const pageSubTitle = <AuthPageSubTitle>Please provide the details below</AuthPageSubTitle>;

export enum NotificationTypes {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error"
}

const ForgotPasswordForm = (props: Props) => {
  const [isLoading, setLoading] = useState(false);
  const [showSecretKeyInput, setShowSecretKeyInput] = useState(false);
  const [resetFields, setResetFields] = useState(false);
  const { getFieldDecorator } = props.form;

  const onSubmit = async (username: string, token: string | undefined, verifyToken: boolean) => {
    setLoading(true);
    try {
      const response = await forgotPassword(username, token, verifyToken);
      const { data } = response;
      if (data) {
        if (data.isEmail) {
          setResetFields(true);
          openNotificationWithIcon(
            NotificationTypes.SUCCESS,
            "Reset Link Generated Successfully",
            "Please check you mail for further instructions."
          );
        } else if (data.hasToken) {
          setShowSecretKeyInput(true);
        }

        if (data.result) {
          window.location = data.result;
        }
      }
    } catch (err) {
      console.log(err);
      setResetFields(true);
      const errorMessage = apiIndependentFunctions.isUserError(err)
        ? getUserErrorMessage(err)
        : "Failed to Generate Reset Link";
      if (err.message) {
        openNotificationWithIcon(NotificationTypes.ERROR, err.message, errorMessage);
      } else {
        openNotificationWithIcon(NotificationTypes.ERROR, errorMessage, "");
      }
    } finally {
      setLoading(false);
      if (resetFields) {
        if (showSecretKeyInput) {
          props.form.resetFields(["secretKey"]);
        } else {
          props.form.resetFields();
        }
        setResetFields(false);
      }
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        let verifyToken = false;
        if (values.secretKey) {
          verifyToken = true;
        }
        await onSubmit(values.username, values.secretKey, verifyToken);
      }
    });
  };

  const PageForm = (
    <Form>
      <FormItem label="Email/Employee ID" required={false} colon={false}>
        {getFieldDecorator("username", {
          rules: [
            {
              required: true,
              message: "Please enter your E-mail or Employee ID!"
            }
          ]
        })(
          <Input
            style={{ marginTop: 8 }}
            disabled={showSecretKeyInput}
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Enter your registered E-mail/Employee ID"
          />
        )}
      </FormItem>
      {showSecretKeyInput ? (
        <FormItem label="Secret Key" required={false} colon={false}>
          {getFieldDecorator("secretKey", {
            rules: [
              {
                required: true,
                message: "Please enter a Secret Key"
              }
            ]
          })(<Input placeholder="Enter the Secret Key you received" />)}
        </FormItem>
      ) : (
        <div />
      )}
      <LoginFormButton
        style={{ justifySelf: "end" }}
        loading={isLoading}
        onClick={handleSubmit}
        type="primary"
        htmlType="submit"
      >
        {showSecretKeyInput ? "Reset Password" : "Identify Me"}
      </LoginFormButton>
      {showSecretKeyInput ? (
        <div />
      ) : (
        <div style={{ alignSelf: "center" }}>
          <Link to="/" style={{ color: "#666666" }}>
            Already a User? <span style={{ color: "#1890ff" }}>Sign In</span>
          </Link>
        </div>
      )}
    </Form>
  );

  return <AuthPageTemplate title={pageTitle} subTitle={pageSubTitle} pageForm={PageForm} />;
};

const createOptions: FormCreateOption<any> = {};
const ForgotPassword = Form.create(createOptions)(ForgotPasswordForm);
export default ForgotPassword;
