import { ValidationError } from "class-validator";
import { LIMIT_DECIMAL_PLACE_COUNT, PROPERTY_DECIMAL_PLACE_COUNT } from "common/config";
import _ from "lodash";
import { nanoid } from "nanoid";
import { v4 as uuid } from "uuid";

export function assertNever(x: never): never {
  throw neverError(x);
}

export function failIfReached(message: string): never {
  throw new Error(message);
}

export const neverError = (str: string) => {
  return new Error(str);
};

export const capitalizeFirstLetter = (s: string) => {
  return s.length ? s.charAt(0).toUpperCase() + s.slice(1) : "";
};

// Based on https://github.com/lodash/lodash/blob/master/.internal/createRound.js
function round(num: number, precisionIn: number) {
  const precision =
    precisionIn == null
      ? 0
      : precisionIn >= 0
      ? Math.min(precisionIn, 292)
      : Math.max(precisionIn, -292);
  if (precision) {
    // Shift with exponential notation to avoid floating-point issues.
    // See [MDN](https://mdn.io/round#Examples) for more details.
    let pair = `${num}e`.split("e");
    const value = Math.round(+`${pair[0]}e${+pair[1] + precision}`);

    pair = `${value}e`.split("e");
    return +`${pair[0]}e${+pair[1] - precision}`;
  }

  return Math.round(num);
}

// TODO: Instead of manually converting string to number, we should migrate snapshot and content and convert string values to number
/**
 * If x > 1 OR n is 0, show at most n decimal places
 * If x < 1, show at most n significant digits
 * @param x
 * @param n
 */
export function roundNumber(x: number, n: number = PROPERTY_DECIMAL_PLACE_COUNT): number {
  x = +x;
  if (isNaN(x)) {
    throw new Error("Given input is not a number");
  }
  if (x > 1 || n < 1) {
    return round(x, n);
  } else {
    return +x.toPrecision(n);
  }
}

export function formatNumber(
  x: number | undefined | null,
  decimalPlaces: number = LIMIT_DECIMAL_PLACE_COUNT
): string {
  return _.isNil(x) ? "" : roundNumber(x, decimalPlaces).toString();
}

export function quantityToString(d: { value: number; unit: string }) {
  return _.isNil(d.value)
    ? ""
    : `${formatNumber(d.value, LIMIT_DECIMAL_PLACE_COUNT)} ${d.unit}`.trim();
}

export function encodeBase64(data: string) {
  const buffer = Buffer.from(data, "utf8");
  return buffer.toString("base64");
}

export function decodeBase64(data: string) {
  const buffer = Buffer.from(data, "base64");
  return buffer.toString("utf8");
}

export const convertCamelCaseToEnglish = (val: string, upperCase: boolean = false) => {
  if (val == null || val === "") {
    return "";
  }

  const camelCase = val.trim();
  let newText = "";
  for (let i = 0; i < camelCase.length; i++) {
    if (/[A-Z]/.test(camelCase[i]) && i !== 0 && /[a-z]/.test(camelCase[i - 1])) {
      newText += " ";
    }
    if (i === 0 && /[a-z]/.test(camelCase[i])) {
      newText += upperCase ? camelCase[i].toUpperCase() : camelCase[i].toLowerCase();
    } else {
      if (camelCase[i] === "_") {
        newText += " ";
      } else {
        newText += upperCase ? camelCase[i] : camelCase[i].toLowerCase();
      }
    }
  }

  return newText;
};

interface IConstraints {
  [type: string]: string;
}

export function createValidationError(property: string, value: any, constraints: IConstraints) {
  const validationError = new ValidationError();
  validationError.property = property;
  validationError.constraints = constraints;
  validationError.value = value;
  return validationError;
}

export function getRandomInt(max: number) {
  return getRandomIntInRange(1, max);
}

export function getRandomIntInRange(min: number, max: number) {
  const minN = Math.ceil(min);
  const maxN = Math.floor(max);
  return Math.floor(Math.random() * (maxN - minN)) + minN; // The maximum is exclusive and the minimum is inclusive
}

export function getDate(value: Date | string | undefined | null): number | null {
  if (_.isNil(value)) return null;

  let dateNumber: number;
  if (value instanceof Date) {
    dateNumber = value.getTime();
  } else {
    dateNumber = Date.parse(value);
  }

  if (isNaN(dateNumber)) return null;

  return dateNumber;
}

export function getDateTime(timestamp: number) {
  const date = new Date(timestamp);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
}

export function generateUniqueId(): string {
  return nanoid();
}

/**
 * Generate and return an UUID
 */
export function generateUuid(): string {
  return uuid();
}

/**
 * A type guard function to avoid type errors while accessing properties of an object
 * @param obj Any object to check whether the given key exists in the object
 * @param key Key to check
 * @returns boolean indicating whether the key exists in the object
 */
export function hasKey<K extends string>(obj: object, key: K): obj is { [key in K]: any } {
  return key in obj;
}
