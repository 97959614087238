import React, { SVGProps } from "react";

const LeucineLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={105} height={28} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#a)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M73.837 7.2c.935 0 1.73.124 2.386.37a6.327 6.327 0 0 1 1.801 1.048L76.51 10.44a4.455 4.455 0 0 0-1.185-.7 3.791 3.791 0 0 0-1.413-.257 2.586 2.586 0 0 0-1.496.464c-.452.31-.813.798-1.082 1.465-.27.667-.404 1.533-.405 2.597 0 1.046.13 1.899.391 2.56.26.66.62 1.145 1.077 1.455a2.738 2.738 0 0 0 1.572.464c.652 0 1.194-.122 1.627-.366.42-.234.82-.5 1.197-.796l1.402 1.785c-.53.51-1.14.928-1.807 1.24-.715.344-1.577.516-2.588.516-1.179 0-2.225-.262-3.138-.785-.912-.523-1.627-1.293-2.145-2.31-.518-1.017-.776-2.27-.776-3.761 0-1.453.268-2.686.804-3.7.536-1.014 1.266-1.786 2.188-2.315.923-.53 1.958-.796 3.105-.796ZM34.013 20.545V7.529h3.086v10.585h5.335l.32 2.433h-8.741Zm14.24-10.819v3.127h4.517v2.161h-4.517v3.325h5.128l.32 2.207h-8.535V7.527H53.7l-.32 2.198h-5.128Zm17.506 6.462v-8.66h-3.096v7.514c0 1.17-.106 2.052-.32 2.644-.213.591-.693.887-1.44.887-.746 0-1.226-.296-1.439-.887-.213-.592-.32-1.473-.32-2.644V7.528h-3.096v8.658c0 .927.179 1.74.537 2.442.356.7.92 1.274 1.613 1.644.724.394 1.626.591 2.705.591 1.08 0 1.978-.205 2.696-.615a3.998 3.998 0 0 0 1.619-1.671c.36-.704.541-1.5.541-2.39Zm17.755 4.358V7.527h-3.088v13.018h3.088Zm8.192 0-3.435-10.472a44.126 44.126 0 0 1 .301 2.343c.05.485.091 1 .123 1.545.03.544.047 1.102.047 1.672v4.91h-2.625V7.528h3.849l3.33 10.418a33.651 33.651 0 0 1-.395-3.25 29.074 29.074 0 0 1-.075-2.178v-4.99h2.625v13.018h-3.745Zm9.423-7.692V9.726h5.129l.32-2.198h-8.534v13.018h8.533l-.321-2.207h-5.127v-3.325h4.516v-2.16h-4.516Z"
        fill="#1D84FF"
      />
      <ellipse cx={14.643} cy={14} rx={14.027} ry={14} fill="#1D84FF" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.942 13.867H15.29a.508.508 0 0 1-.514-.503V7.702c0-.277.23-.502.514-.502h5.65c.285 0 .515.225.515.502v5.662c0 .278-.23.503-.514.503Zm-13.177.217-.001 1.426c-.001 1.448 0 2.895.003 4.34.002.685.31 1.017.984 1.017h8.768c.299 0 .56-.08.776-.31.936-.987 1.875-1.974 2.816-2.96.148-.155.28-.328.32-.542.067-.361.015-.403-.345-.403l-2.911-.001c-1.941-.002-3.882-.003-5.822.001-.282.002-.344-.076-.344-.347.008-2.25.008-4.498.007-6.747v-1.84c0-.477-.129-.62-.59-.448-.103.038-.191.13-.273.214-.028.03-.055.058-.082.083l-.844.797c-.693.654-1.386 1.31-2.085 1.958-.274.254-.378.547-.378.911.003.948.002 1.9 0 2.85Z"
        fill="#fff"
      />
    </g>
    <defs>
      <clipPath id="a">
        <path fill="#fff" transform="translate(.617)" d="M0 0h104.233v28H0z" />
      </clipPath>
    </defs>
  </svg>
);

export default LeucineLogo;
