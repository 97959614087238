import { getUserErrorMessage, registerUser } from "client/services/api";
import * as apiIndependentFunctions from "client/services/apiFunctions";
import { openNotificationWithIcon } from "client/utils";
import React from "react";
import { AuthPageSubTitle, PageHeader, PageTitleWrapper } from "../../styles";
import { AuthPageTemplate } from "../Common/AuthPageTemplate";
import { RegisterUserForm } from "./RegisterUserForm";

export enum NotificationTypes {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error"
}

const PageTitle = (
  <PageTitleWrapper>
    <PageHeader>Welcome</PageHeader>
  </PageTitleWrapper>
);

const pageSubtitle = (
  <AuthPageSubTitle>Help us identify you by providing the following details</AuthPageSubTitle>
);

export default class RegisterUser extends React.Component {
  submit = async (userId: string, token: string) => {
    try {
      const redirectLink: Location = await registerUser(userId, token);
      window.location = redirectLink;
    } catch (err) {
      console.error(err);
      const errorMessage = apiIndependentFunctions.isUserError(err)
        ? getUserErrorMessage(err)
        : "Failed to Generate Reset Link";
      openNotificationWithIcon(NotificationTypes.ERROR, err.message, errorMessage);
    }
  };

  render() {
    return (
      <AuthPageTemplate
        title={PageTitle}
        subTitle={pageSubtitle}
        pageForm={<RegisterUserForm onSubmit={this.submit} />}
      />
    );
  }
}
