import React, { SVGProps } from "react";

const CleenLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={133} height={42} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M58.804 10.771c1.366 0 2.528.181 3.487.543.956.362 1.846.88 2.633 1.533l-2.217 2.668a6.467 6.467 0 0 0-1.733-1.024 5.52 5.52 0 0 0-2.062-.379 3.776 3.776 0 0 0-2.187.68c-.658.455-1.184 1.169-1.576 2.143-.392.975-.59 2.242-.591 3.8 0 1.532.19 2.781.57 3.748.381.967.905 1.677 1.571 2.13a3.99 3.99 0 0 0 2.297.68c.953 0 1.746-.178 2.38-.535a13.436 13.436 0 0 0 1.746-1.169l2.05 2.612a9.751 9.751 0 0 1-2.64 1.814c-1.047.504-2.308.756-3.783.756-1.724 0-3.253-.382-4.586-1.147-1.334-.765-2.38-1.893-3.141-3.383-.756-1.489-1.134-3.324-1.134-5.506 0-2.126.391-3.931 1.175-5.417.784-1.485 1.85-2.615 3.198-3.389 1.35-.77 2.865-1.157 4.543-1.158zm64.268.482 4.869 15.247a48.11 48.11 0 0 1-.579-4.758 44.625 44.625 0 0 1-.11-3.189v-7.3h3.838v19.055h-5.474l-5.02-15.329c.061.413.133.91.213 1.493.081.582.157 1.228.228 1.939.072.71.132 1.463.178 2.261.046.798.069 1.614.069 2.447v7.19h-3.837V11.254h5.625zm-25.694 0-.454 3.217h-7.5v4.578h6.603v3.163h-6.602v4.866h7.495l.468 3.232h-12.47V11.253h12.46zm16.514 0-.467 3.217h-7.496v4.578h6.602v3.163h-6.602v4.866h7.496l.467 3.232h-12.47V11.253h12.47zm-40.97 0v15.494h7.797l.468 3.56H68.41V11.254h4.51zM20.953 41.89c11.572 0 20.953-9.378 20.953-20.945C41.906 9.377 32.525 0 20.953 0S0 9.377 0 20.945C0 32.512 9.38 41.89 20.953 41.89z"
      fill="#1D84FF"
    />
    <path
      d="M12.478 15.612c1.433-1.434 2.871-2.861 4.298-4.302.364-.368.764-.556 1.295-.537 1.898.066 3.798.106 5.697.158.204.006.465-.03.654.056.634.29.62.56.139 1.038a14064.67 14064.67 0 0 1-8.64 8.63c-.274.272-.289.413-.006.693a2083.61 2083.61 0 0 1 8.73 8.734c.358.36.37.453-.06.749-.256.175-.56.217-.866.226-1.93.05-3.862.094-5.792.16-.447.014-.788-.167-1.087-.465-2.924-2.921-5.845-5.843-8.765-8.766-.673-.675-.65-1.315.036-2.004 1.454-1.459 2.91-2.916 4.367-4.37zm8.662 5.987 5.866 5.867a.755.755 0 0 0 1.068 0l6.016-6.017a.755.755 0 0 0 0-1.068l-5.866-5.86a.755.755 0 0 0-1.068 0l-6.016 6.012a.755.755 0 0 0 0 1.066z"
      fill="#fff"
    />
  </svg>
);

export default CleenLogo;
