import { Col, Row, Spin } from "antd";
import AuthBg from "client/public/images/auth_bg.jpg";
import CleenLogo from "client/public/svgs/CleenLogo";
import LeucineLogo from "client/public/svgs/LeucineLogo";
import { DimensionWrapper } from "client/styleUtils";
import React from "react";
import styled from "styled-components";

const FluidContainer = styled.div.attrs({
  id: "fluid-container"
})`
  background-image: url(${AuthBg});
  background-color: ${props => props.theme.colors.white};
  background-position: top;
  background-size: cover;
  height: calc(100% - 60px);
  width: 100%;

  .product-caption {
    font-size: 14px;
    line-height: 1.57;
    color: ${props => props.theme.alphaColors.black("0.85")};
    margin-bottom: 40px;
  }

  .ant-form-item {
    margin-bottom: 24px;

    .ant-form-item-label {
      margin-bottom: 8px;
      label {
        font-size: 14px !important;
        line-height: 1.57;
        color: ${props => props.theme.alphaColors.black("0.85")};
      }
    }
  }

  .brand-footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    background-color: #fafafa;
    padding: 16px 32px;

    > div {
      display: flex;
      align-items: center;
      margin-left: auto;
      font-size: 12px;
      line-height: 1.33;
      letter-spacing: 0.32px;
      color: #999999;

      > a {
        line-height: 0;
        margin-left: 8px;
      }
    }
  }
`;

const PageIsLoadingWrapper = styled.div.attrs({
  id: "page-loading-wrapper"
})`
  text-align: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

type Props = {
  isLoading?: boolean;
  title?: React.ReactElement;
  subTitle?: React.ReactElement;
  pageForm?: React.ComponentElement<any, any> | JSX.Element;
};

export const AuthPageTemplate = (props: Props) => {
  return (
    <FluidContainer>
      <DimensionWrapper as={Row} height="100%">
        <DimensionWrapper as={Col} xl={8} lg={8} md={24} height="100%">
          <DimensionWrapper
            as={Row}
            justify="center"
            align="middle"
            type="flex"
            height="100%"
            width="100%"
          >
            <Col span={18}>
              {props.isLoading ? (
                <PageIsLoadingWrapper>
                  <Spin />
                </PageIsLoadingWrapper>
              ) : (
                <>
                  <CleenLogo />
                  <p className="product-caption">Cleaning Validation Risk Management Platform</p>
                  {props.title ? props.title : null}
                  {props.subTitle ? props.subTitle : null}
                  {props.pageForm ? props.pageForm : null}
                </>
              )}
            </Col>
          </DimensionWrapper>
        </DimensionWrapper>
      </DimensionWrapper>
      <div className="brand-footer">
        <div>
          A Product By
          <a href="https://www.leucinetech.com">
            <LeucineLogo />
          </a>
        </div>
      </div>
    </FluidContainer>
  );
};
