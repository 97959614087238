import { Icon, Input } from "antd";
import Form, { FormComponentProps, FormCreateOption } from "antd/lib/form";
import * as apiIndependentFunctions from "client/services/apiFunctions";
import { openNotificationWithIcon } from "client/utils";
import React, { useState } from "react";
import { getUserErrorMessage } from "../../../../services/api";
import { AuthPageSubTitle, LoginFormButton, PageHeader, PageTitleWrapper } from "../../styles";
import { AuthPageTemplate } from "../Common/AuthPageTemplate";

export enum NotificationTypes {
  SUCCESS = "success",
  WARNING = "warning",
  INFO = "info",
  ERROR = "error"
}

type Props = FormComponentProps;

const FormItem = Form.Item;
const pageTitle = (
  <PageTitleWrapper>
    <PageHeader>Link has expired!</PageHeader>
  </PageTitleWrapper>
);
const pageSubTitle = <AuthPageSubTitle>You will need a fresh link </AuthPageSubTitle>;

const PasswordExpiryForm = (props: Props) => {
  const [isLoading, setLoading] = useState(false);
  const { getFieldDecorator } = props.form;

  const forgotPassword = async (email: string) => {
    setLoading(true);
    try {
      openNotificationWithIcon(
        NotificationTypes.SUCCESS,
        "Reset Link Generated Successfully",
        "Please check you mail for further instructions."
      );
    } catch (err) {
      console.log(err);
      const errorMessage = apiIndependentFunctions.isUserError(err)
        ? getUserErrorMessage(err)
        : "Failed to Generate Reset Link";
      openNotificationWithIcon(NotificationTypes.ERROR, err.message, errorMessage);
    }
    setLoading(false);
  };

  const handleSubmit = e => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        await forgotPassword(values.email);
        props.form.resetFields();
      }
    });
  };

  const PasswordExpiryFormComponent = (
    <Form onSubmit={handleSubmit}>
      <FormItem label="Your Email" required={false} colon={false}>
        {getFieldDecorator("email", {
          rules: [
            {
              type: "email",
              required: true,
              message: "Please input your email!"
            }
          ]
        })(
          <Input
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
            placeholder="Email"
          />
        )}
      </FormItem>
      <LoginFormButton type="primary" htmlType="submit" loading={isLoading}>
        Get a fresh link
      </LoginFormButton>
    </Form>
  );

  return (
    <AuthPageTemplate
      title={pageTitle}
      subTitle={pageSubTitle}
      pageForm={PasswordExpiryFormComponent}
    />
  );
};

const createOptions: FormCreateOption<any> = {};
const PasswordExpiry = Form.create(createOptions)(PasswordExpiryForm);
export default PasswordExpiry;
