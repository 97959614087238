import { matchStatusAndThrowError } from "./api";

const userErrors = new Set([
  "UserBlockedError",
  "ChangePasswordOnFirstLogin",
  "ChangePasswordOnPasswordExpiry",
  "PasswordMinLengthError",
  "PasswordNotComplexError",
  "PasswordAlreadyUsed",
  "InvalidEmailOrPasswordError",
  "RequestingUserNotfound",
  "LoginAttemptsExceeded",
  "InvalidToken"
]);

export function isUserError(error: { [index: string]: any }) {
  const name = error && error.errName;
  return userErrors.has(name);
}

export function nothingChangeError() {
  return {
    errName: "NothingToChangeError",
    name: "Nothing to change",
    message:
      "No changes have been made from the current values. Please check all fields and try again."
  };
}

export function naivePaginate<T = any>(
  data: T[],
  o?: {
    pagination?: {
      pageNumber: number;
      pageLimit: number;
    };
    unpaginated?: boolean;
  }
) {
  if (o?.unpaginated) {
    return data;
  }
  const { pageNumber, pageLimit } = (o && o.pagination) || {
    pageNumber: 1,
    pageLimit: 10
  };

  const from = (pageNumber - 1) * pageLimit;

  const dataPaginated = data.slice(from, from + pageLimit);

  return dataPaginated;
}

export function handleErrorOrThrow(e: any, action: string) {
  if (e.response) {
    matchStatusAndThrowError(e, action);
  } else throw e;
}

export function filterAndPaginate(
  data: any[],
  o?: {
    pagination?: {
      pageNumber: number;
      pageLimit: number;
    };
    search?: string;
    unpaginated?: boolean;
  }
) {
  const searchTerm = (o && o.search && o.search.toLowerCase()) || "";

  const dataFiltered = data.filter(d => {
    const name = d.name.toLowerCase();
    return name.search(searchTerm) !== -1;
  });
  const dataPaginated = naivePaginate(dataFiltered, o);

  return [dataPaginated, dataFiltered];
}
