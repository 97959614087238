import styled from "styled-components";
import { Form, Button } from "antd";

export const AuthForm = styled(Form).attrs({
  id: "auth-form"
})`
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 45%;
`;

export const LoginFormButton = styled(Button).attrs({
  id: "login-form-button"
})`
  border-radius: 2px;
  background-color: ${props => props.theme.alphaColors.dodgerBlue()} !important;
  color: ${props => props.theme.colors.white} !important;
  border-color: ${props => props.theme.alphaColors.dodgerBlue()} !important;
  letter-spacing: 0.03rem;
  width: 100%;
  font-weight: 600;
  margin-block: 8px 24px;

  :disabled {
    opacity: 0.5;
  }
`;

export const PageTitleWrapper = styled.div.attrs({
  id: "page-title-wrapper"
})`
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: max-content;
  grid-column-gap: 10px;
`;

export const PageHeader = styled.div.attrs({
  id: "page-header"
})`
  margin-bottom: 0px;
  font-weight: 600;
  color: ${props => props.theme.alphaColors.black("0.85")};
  font-size: 38px;
  line-height: 1.21;
`;

export const AuthPageSubTitle = styled.p.attrs({
  id: "auth-sub-title"
})`
  color: ${props => props.theme.alphaColors.black("0.45")};
  margin-block: 8px 40px;
  font-size: 14px;
  line-height: 1.57;
`;
